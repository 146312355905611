.button {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  padding: 20px 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  font-weight: bold;
  transition:
    0.3s transform ease,
    0.2s background linear;
}
.button:active:not(:disabled) {
  transform: scale(0.95);
}
.button.filled {
  border: none;
  color: white;
  background: var(--color-red);
}

.button.outlined {
  border: 1px solid var(--color-red);
  color: var(--color-red);
  background: transparent;
}

.button svg {
  margin-left: 10px;
  transition: 0.3s transform ease;
}

.button.reversed svg {
  margin-left: 0;
  margin-right: 10px;
  transform: rotateZ(180deg);
}

.button.outlined svg path,
.button.outlined svg line {
  stroke: var(--color-red);
}

.button.filled svg path,
.button.filled svg line {
  stroke: white;
}

.button:hover svg {
  transform: translateX(3px);
}

.button.reversed:hover svg {
  transform: rotateZ(180deg) translateX(3px);
}

.button:disabled:hover svg {
  transform: none;
}

.button.filled:disabled {
  background: var(--color-grey-darker);
  cursor: not-allowed;
}

.button.outlined:disabled {
  border-color: var(--color-grey-darker);
  color: var(--color-grey-darker);
  cursor: not-allowed;
}
