.container {
  padding: 0 15px;
}
.label {
  font-size: 12px;
  display: flex;
  gap: 2px;
  font-size: 12px;
  padding: 0 2px;
}
.labelText {
  flex-shrink: 0;
  display: block;
}
.requiredIndicator {
  flex-shrink: 0;
  display: block;
  color: var(--color-red);
  font-size: 12px;
}
.disabled .label {
  color: #959595;
}
.options {
  display: flex;
  gap: 15px;
}
.option {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-top: 6px;
  padding-right: 15px;
}
.option:focus {
  outline: none;
}
@media (pointer: fine) {
  .option:focus .circleOuter {
    border-color: black;
  }
}
.disabled .option {
  pointer-events: none;
}
.name {
  font-size: 16px;
  user-select: none;
}
.disabled .name {
  color: #959595;
}
.circleOuter {
  border-radius: 100%;
  border: 1px solid rgba(149, 149, 149, 0.4);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled .circleOuter {
  border-color: rgba(149, 149, 149, 0.4);
}
.circleInner {
  border-radius: 100%;
  width: 14px;
  height: 14px;
  background: var(--color-red);
  opacity: 0;
}

:not(.selected) .circleInner {
  transform: scale(0.6);
}

.selected .circleInner {
  opacity: 1;
  transform: none;

  transition:
    0.2s transform ease,
    0.3s opacity ease;
}
.option:active:not(.selected) .circleInner {
  opacity: 0.4;
}
.disabled .selected .circleInner {
  background: rgba(149, 149, 149, 0.4);
}
