.wrapper {
  width: 800px;
  max-width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 15px;
  height: 70vh;
  box-sizing: border-box;
  text-align: center;
  animation: wrapperSupportForEmojiAnimation 1s ease-in-out;
  animation-iteration-count: 1;
}
.title {
  font-size: 5rem;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.failedLinkTitle {
  font-weight: 500;
  font-size: 10px;
  margin-bottom: 5px;
}
.failedLink {
  color: var(--color-red);
  word-break: break-all;
}
.digit {
  animation: digitAnimation 0.6s ease;
  animation-iteration-count: 1;
}

.emoji {
  font-size: 4.5rem;
  animation: emojiAnimation 0.6s ease-in-out;
  animation-iteration-count: 1;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  animation: textAnimation 1.1s ease;
  animation-iteration-count: 1;
}
.text {
  animation: textAnimation 1.2s ease;
  animation-iteration-count: 1;
}

@keyframes emojiAnimation {
  from {
    transform: scale(5);
  }
  to {
    transform: scale(1);
  }
}
@keyframes wrapperSupportForEmojiAnimation {
  from {
    overflow: hidden;
  }
  to {
    overflow: auto;
  }
}

@keyframes textAnimation {
  0%,
  80% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
  }
}
@keyframes digitAnimation {
  0%,
  80% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
  }
}
