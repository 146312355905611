.container {
  padding: 20px;
  border-radius: 16px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

.container.allSigned {
  color: #639a44;
  background: #ecfce0;
}

.container.partiallySigned {
  color: #c39900;
  background: #fcf9d4;
}

.icon {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allSigned .icon path {
  fill: #639a44;
}

.partiallySigned .icon path {
  fill: #c39900;
}
