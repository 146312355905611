.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px 40px 15px;
}
.container svg {
  width: 120px;
  height: auto;
}
