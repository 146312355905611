.inputWrapper {
  width: 100%;
  position: relative;
  padding-top: 7px;
}
.input {
  -webkit-appearance: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  border: 1px solid rgba(149, 149, 149, 0.4);
  padding: 11px 14px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
}
.input:focus:not(:disabled) {
  border-color: black;
  outline: none;
}
.input:disabled {
  color: #959595;
  -webkit-text-fill-color: #959595;
  opacity: 1;
  background: transparent;
}
.error .input {
  border-color: var(--color-red);
}
.label {
  z-index: 1;
  position: absolute;
  display: flex;
  gap: 2px;
  font-size: 12px;
  left: 13px;
  top: 0;
  padding: 0 2px;
  background: white;
  max-width: calc(100% - 13px);
}
.labelText {
  flex-shrink: 0;
  flex-grow: 1;
  display: block;
  white-space: nowrap;
  width: calc(100% - 5px);
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.error .label {
  color: var(--color-red);
}
.isDisabled .label {
  color: #959595;
}
.requiredIndicator {
  flex-shrink: 1;
  display: block;
  color: var(--color-red);
  font-size: 12px;
}
