.wrapper {
  display: inline-block;
  position: relative;
}
.cellsContainer {
  display: flex;
}
.cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  width: 40px;
  height: 60px;
  border: 1px solid var(--color-grey-darker);
  margin: 0 2px;
  font-weight: 300;
  border-radius: 6px;
  color: var(--color-darkblue);
}

.cell.focused {
  box-shadow: 0 0 0 4px rgba(118, 180, 248, 0.22);
}

.cellValue {
  transition: 0.2s all ease;
  transform: translateY(-10px);
}

.cell.filled .cellValue {
  transform: none;
}

.wrapper .input {
  position: absolute;
  font-size: 32px;
  border: none;
  outline: none;
  width: 40px;
  height: 60px;
  left: 0;
  top: 1px;
  padding: 0;
  background: transparent;
  text-align: center;
}

.input.hidden {
  opacity: 0;
  pointer-events: none;
}
