.content {
  width: 100%;
  max-width: 768px;
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.stepName {
  font-size: 14px;
}

@media (max-width: 375px) {
  .content {
    padding: 0 10px;
  }
}
