.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
}
.container {
}
.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
.text {
  text-align: center;
}
