.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  margin-bottom: 5px;
}

.controlsContainer > *:first-child {
}

.timeoutHint {
  font-size: 14px;
  color: var(--color-grey-darker);
  text-align: center;
}

.loadingHint {
  font-size: 14px;
  color: var(--color-grey-darker);
  text-align: center;
}
.description {
  font-size: 14px;
  line-height: 18px;
  max-width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .controlsContainer {
  }
  .controlsContainer > *:first-child {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 280px) {
  .controlsContainer {
    flex-wrap: wrap;
  }
  .controlsContainer button {
  }
}
