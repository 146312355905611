.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  margin-bottom: 20px;
}

.callButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
}

.callButton svg {
  margin-left: 0;
  transform: none;
  margin-right: 10px;
  width: 15px;
  height: auto;
}

.callButton:hover svg {
  transform: none;
}

.callButton svg path {
  fill: white;
}
