.documentList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.error {
  color: var(--color-red);
  font-weight: 500;
  font-size: 12px;
  margin: 12px 22px;
  transition: 0.2s all ease;
}
.error.hidden {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
}
.docsLoadingText {
  text-align: center;
  color: grey;
  font-size: 14px;
}
