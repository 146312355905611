.steps {
  display: flex;
  gap: 4px;
  font-size: 11px;
  font-weight: 500;
  align-items: flex-end;
}
.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 25%;
}
.stepName {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: center;
  color: var(--color-red);
}
.awaiting .stepName {
  color: #c1c1c1;
}
.current .stepName {
  transition: color 0.3s linear;
}
.completed {
}
.awaiting {
}
.current {
}
.outerBar {
  width: 100%;
  height: 4px;
  background: #efefef;
}
.current .outerBar {
  background: rgba(240, 50, 38, 0.5);
  transition: 0.3s background-color linear;
}
.completed .outerBar {
  background: rgba(240, 50, 38, 0.5);
}
.innerBar {
  height: 4px;
  background: var(--color-red);
}
.current .innerBar {
  width: 50%;
  transition: width 0.3s ease;
}
.completed .innerBar {
  width: 100%;
}
.awaiting .innerBar {
  width: 0;
  background: transparent;
}
.step:first-child .outerBar,
.step:first-child:not(.awaiting) .innerBar {
  border-radius: 4px 0 0 4px;
}
.step:last-child .outerBar,
.step:last-child:not(.current) .innerBar {
  border-radius: 0 4px 4px 0;
}
