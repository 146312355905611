.container {
  height: available;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
  overflow: hidden;
}
.delayed {
  animation: fadeIn 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.delayedHidden {
  animation: fadeOut 2s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.title {
  font-size: 22px;
  text-align: center;
  margin: 0;
  font-weight: 500;
}
.text {
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;
  text-align: center;
  width: 800px;
  max-width: 100%;
}

.textSmall {
}

.buttons {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.action {
  color: var(--color-red);
  text-decoration: underline;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    display: block;
  }
  to {
    display: none;
  }
}
